import React, { useState, useEffect, useContext } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import Nav from './Nav';
import Public from './Public';
import Profile from './Profile';
import Gate from './Gate';
import Collections from './components/collections/Collections';
import Protected from './Protected';
import Home from './components/Home';
import Mashboard from './components/mashboard/Mashboard';
import PreMash from './components/mashboard/PreMash';
import PreMint from './components/mashboard/PreMint';
import Nft from './components/Nft';
import Edit from './components/mashboard/Edit';
import './App.css';
import UserContext from './UserContext';
import './fonts/WTJazz/WTJazz-BoldExpanded.ttf';

const Router = () => {

  useEffect(() => {
    setRoute()
    window.addEventListener('hashchange', setRoute)
    return () =>  window.removeEventListener('hashchange', setRoute)
  }, []);

  const userContext = useContext(UserContext);

  const [ userPayLoad, setUserPayLoad ] = useState({
      username: userContext.username,
      user_wallet_address: userContext.user_wallet_address
    });

  function setRoute() {
    const location = window.location.href.split('/')
    const pathname = location[location.length-1]
  };

  const updateUserWalletAddress = (newWalletAddress) => {
    const updatedUserPayload = {...userPayLoad, ...{user_wallet_address:newWalletAddress}};  // merge incoming wallet address property to the userPayLoad
    setUserPayLoad(updatedUserPayload); // update userPayLoad data via hook method
  }

  const updateUserPayloadWithUserData = (newUsername) => {
    console.log('updating userPayLoad with new username: ', newUsername);
    const updatedUserPayload = {...userPayLoad, ...{username:newUsername}};  // put username to the userPayLoad
    setUserPayLoad(updatedUserPayload); // update userPayLoad data via hook method
    console.log('new userPayLoad: ', userPayLoad);
  }
  
  return (
    <UserContext.Provider value={userPayLoad}> {/* Note: the UserContext is linked with the userPayLoad */}
      <HashRouter>
        <Nav walletUpdateCallback={updateUserWalletAddress} />
        <div>
          <hr style={{ margin: '8px 0px', width: '100%', borderTop: '1px solid #F5F5F5'}}></hr>
        </div>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/protected" component={Protected} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/collections" component={Collections} />
          <Route exact path="/mashboard" component={Mashboard} />
          <Route exact path="/premash" component={PreMash} />
          <Route exact path="/premint" component={PreMint} />
          <Route exact path="/nft" component={Nft} />
          <Route exact path="/edit" component={Edit} />
          <Route exact path="/gate" component={Gate} />
          <Route component={Public}/>
        </Switch>
      </HashRouter>
    </UserContext.Provider>
  )
}

export default Router;