import { faker } from '@faker-js/faker';
import { Factory } from "miragejs";


export default Factory.extend({
    userId() {
        return faker.dataType.uuid()
    },
    userName() {
        return faker.internet.userName()
    },
    preferredFirstName() {
        return faker.name.firstName()
    },
    preferredLasttName() {
        return faker.name.lastName()
    },
    emailAddress() {
        return faker.internet.email()
    },
    profileImage() {
        return faker.image.avatar()
    },
    roles() {
        return [
            faker.random.arrayElement([
                { roleCode: 'ARTS'},
                { roleCode: 'MASH'}
            ])
        ]
    },
    walletId: null
})