import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Player } from 'video-react';
import { mintNFT } from '../../interface/api/MashNFT';
import { Helmet } from "react-helmet";
import Utilities from '../../utility/utilities';
import pinJSONToIPFS from "../../utility/pinata";
import UserContext from '../../UserContext';
import './PreMint.css';
const PreMint = (props) => {

  const location = useLocation();
  const mashPayload = location.payLoad;
  console.log('Mash payload received from Edit page: ', mashPayload);

  const [ minting, setMinting ] = useState(false);
  const [ minted, setMinted ] = useState(false);

  const userContext = useContext(UserContext);

  const totalListPrice = parseFloat(mashPayload.video.list_price) + parseFloat(mashPayload.audio.list_price);
  const totalRoyalties = parseFloat(mashPayload.video.royalty_fee) + parseFloat(mashPayload.audio.royalty_fee);
  const grandTotal = parseFloat(totalListPrice) + parseFloat(mashPayload.mash_fee) + parseFloat(totalRoyalties);

  useEffect(() => {
    if (minted) {
      console.log('Minting is DONE: ', minted);
      Utilities.sendPayLoad(props, '/nft', mashPayload);
    }
  }, [minted]);

  const isWalletConnected = () => {
    let walletConnected = false;
    if (userContext.user_wallet_address.length > 0 ) {
      walletConnected = true
    }
    return walletConnected;
  }

  // const mintNewNFT = () => {
  //   console.log('Minting triggered');
  //   // check if wallet is connected
  //   if (isWalletConnected()) {
  //     setMinting(true);
  //     console.log("minting a new NFT.....");
  //     // show spinner to simulate some processing that is occuring during the minting proces
  //     setTimeout(() => {
  //       // delay a few seconds to simulate processing; removed this when actual code is in place
  //       setMinted(true); // after successully minting a new NFT, set the variable "minted" to true
  //       setMinting(false);
  //     }, 3000);
  //   } else {
  //     alert("Wallet not connected.\n🦊 Please connect your wallet before minting.\nClick the 'Connect Wallet' button at top right.");
  //   }
  // }

  const handleMinting = async () => {
    const user_address = "0x2B6C4eFA4e6BDA96fcd0EE321e72E886256c3a2A"; // is this the user_wallet_address??
    const mash_contract_address = "0x38ceacefd520e66e17df580a27af88c99213ae0e";

    if (isWalletConnected()) {
        setMinting(true);

        const token_metadata = new Object();
        token_metadata.mashed_creation_title = mashPayload.title; // does the masher have a way to set a title for the NFT that's about to be created?
        token_metadata.masher_name = mashPayload.username;
        token_metadata.masher_address = mashPayload.user_wallet_address; // if the mashed NFT gets sold, is this the field that gets updated with new owner's wallet address? 
        token_metadata.mashed_creation_price = totalListPrice;
        token_metadata.mashed_creation_royalty = totalRoyalties;
        token_metadata.mash_fees = mashPayload.mash_fee;
        token_metadata.mashed_creation_url = mashPayload.mashed_creation_url;
        token_metadata.original_visual_title = mashPayload.video.title;
        token_metadata.original_visual_artist_name = mashPayload.video.creator;
        token_metadata.original_visual_artist_name_address = mashPayload.video.owner_address;
        token_metadata.original_visual_url = mashPayload.video.media_ipfs_url;
        token_metadata.original_audio_title = mashPayload.audio.title;
        token_metadata.original_audio_artist_name = mashPayload.audio.creator;
        token_metadata.original_audio_artist_name_address = mashPayload.audio.owner_address;
        token_metadata.original_audio_url = mashPayload.audio.media_ipfs_url;

        const token_uri = await pinJSONToIPFS(token_metadata);

        await mintNFT(user_address, token_uri, mash_contract_address);

        setMinted(true);

    } else {
      alert("Wallet not connected.\n🦊 Please connect your wallet before minting.\nClick the 'Connect Wallet' button at top right.");
    }
  }

  const goToPreMashView = () => {
    Utilities.sendPayLoad(props, '/premash', mashPayload);
  }
  
  return (
    <>
      <Helmet htmlAttributes>
          <html lang="en" />
          <title>Pre-mint page</title>
          <meta name="description" content="Pre-mint page" />
      </Helmet>

      <div className="premint">
        
        <div className="flow-wrapper">
          <p onClick={() => {goToPreMashView()}}>&lt; Back to Preview Mash</p>
        </div>
        <br></br>
        <p>PreMint Page</p>
        <br></br>

        <div className="row">
          <div className="previewer">
            <Player
                playsInline
                poster={mashPayload.thumbnail_ipfs_url}
                src={mashPayload.media_ipfs_url}
            />
          </div>
          
          <div className="mashed-details">
            <div>
              <div>
                <p className="field-label">Title</p>
                <p className="field-value">{mashPayload.title}</p>
              </div>
              <div>
                <p className="field-label">Masher</p>
                <p className="field-value">{mashPayload.username}</p>
              </div>
            </div>

            <p className="field-label">Owner Address</p>
            <p className="field-value">{mashPayload.user_wallet_address}</p>

            <div className="more-fields">
              <div>
                <p className="field-label">Total list price</p>
                <p className="field-value">{totalListPrice} ETH</p>
              </div>
              <div>
                <p className="field-label">Total Royalties</p>
                <p className="field-value">{totalRoyalties} ETH</p>
              </div>
              <div>
                <p className="field-label">Mash Fees</p>
                <p className="field-value">{mashPayload.mash_fee} ETH</p>
              </div>
              <div>
                <p className="field-label">Grand Total</p>
                <p className="field-value">{ parseFloat(grandTotal).toFixed(2) } ETH</p>
              </div>
              <div>
                <button onClick={ () => handleMinting() } type="button" style={{ backgroundColor: 'lightgreen'}} className="my-5 py-2 px-4 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-200 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  MINT
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="previewer">
            <Player
                playsInline
                poster={mashPayload.video.thumbnail_ipfs_url}
                src={mashPayload.video.media_ipfs_url}
            />
          </div>

          <div className="video-details">
            <div className="more-fields">
              <div>
                <p className="field-label">Title:</p>
                <p className="field-value">{mashPayload.video.title}</p>
              </div>
              <div>
                <p className="field-label">Video Owner</p>
                <p className="field-value">{mashPayload.video.creator}</p>
              </div>
              <div>
                <p className="field-label">Collection</p>
                <p className="field-value">{mashPayload.title}</p>
              </div>
              <div>
                <p className="field-label">list price</p>
                <p className="field-value">{mashPayload.video.list_price} ETH</p>
              </div>
              <div>
                <p className="field-label">Royalty Fee (10%)</p>
                <p className="field-value">{mashPayload.video.royalty_fee} ETH</p>
              </div>
            </div>
            <div>
                <p className="field-label">Owner Address</p>
                <p className="field-value">{mashPayload.video.owner_address}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="previewer">
            <img src="/images/waveform1.png"/>
          </div>

          <div className="video-details">
            <div className="more-fields">
              <div>
                <p className="field-label">Title:</p>
                <p className="field-value">{mashPayload.audio.title}</p>
              </div>
              <div>
                <p className="field-label">Audio Owner</p>
                <p className="field-value">{mashPayload.audio.creator}</p>
              </div>
              <div>
                <p className="field-label">Collection</p>
                <p className="field-value">{mashPayload.title}</p>
              </div>
              <div>
                <p className="field-label">list price</p>
                <p className="field-value">{mashPayload.audio.list_price} ETH</p>
              </div>
              <div>
                <p className="field-label">Royalty Fee (10%)</p>
                <p className="field-value">{mashPayload.audio.royalty_fee} ETH</p>
              </div>
            </div>
            <div>
                <p className="field-label">Owner Address</p>
                <p className="field-value">{mashPayload.audio.owner_address}</p>
            </div>
          </div>
        </div>
        
        {
          minting ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  {/* the modal itself */}
                <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '75%' }}>
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between py-1 px-4 border-b border-solid border-slate-200 rounded-t">
                      <h4 className="py-2 text-sm font-semibold">
                        Minting in progress....
                      </h4>
                    </div>
                    {/*body*/}
                    <div style={{ textAlign: 'center', width: '100%', border: '1px dotted gray' }} className="relative p-1">

                      <p>Please wait...</p>
                      <p>We are currently minting your MASH clip as an NFT...</p>
                      <p>Your new NFT will be ready soon!</p>

                      <svg style={{ textAlign: 'center', width: '100%' }} role="status" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }


      </div>
    </>
  );
}

export default PreMint;
