import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { Auth, Hub } from 'aws-amplify';
import Form from './Form';
import UserContext from './UserContext';
import Utilities from './utility/utilities';
import './Gate.css';

const Gate = (props) => {

  const userContext = useContext(UserContext);
  console.log('userContext as seen by Profile Page: ', userContext);

  const [user, setUser] = useState(null);
  
  useEffect(() => {
    checkUser();
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signOut') {
        setUser(null)
      }
    });
  }, []);

  const goToCollections = () => {
    checkUser();
    if (user.username !== undefined) Utilities.sendPayLoad(props, '/collections', user);
  }

  async function checkUser() {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes };
      setUser(userInfo);
    } catch (err) { console.log('error: ', err) }
  }

  function signOut() {
    Auth.signOut()
      .catch(err => console.log('error signing out: ', err))
  }
  
  if (user) {

    return (
      <div className="profile-container">
        <h1>Security Check:</h1>
        <p>We need to confirm a few details in order to continue.</p>
        <p>Please check the details below and click 'Proceed &gt;'.</p>
        <br></br>
        <p>Username: {user.username}</p>
        <p>Wallet Address: {userContext.user_wallet_address}</p>
        <br></br>
        <Button onClick={signOut}>Sign Out</Button> 
        <Button onClick={goToCollections}>Proceed &gt;</Button>
      </div>
    );
  }
  return <Form setUser={setUser} />
}

export default Gate;
