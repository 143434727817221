import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import UserContext from './UserContext';
import ConnectWalletButton from './components/ConnectWalletButton';
import './Nav.css';

const Nav = (props) => {
  
  const userContext = useContext(UserContext);

  const updateUserWalletAddress = (walletAddress) => {
    props.walletUpdateCallback(walletAddress); // call method upstream
  }

  return (
    <div className="Nav">
      <Link to={`/`}>
        <span className="logo">mash</span>
      </Link>
      <div style={{ float: 'right', position: 'relative', top: '2px' }}>
        <ConnectWalletButton parentCallback={updateUserWalletAddress} />
      </div>
    </div>

    // <div className="Nav">
    //   <Menu mode="horizontal">
    //     <Menu.Item key='home'>
    //       <Link to={`/`}>
    //       <span className="logo">mash</span>
    //       </Link>
    //     </Menu.Item>
    //   </Menu>
    //   <div style={{ position: 'absolute', top: '0%', right: '0%', marginTop: '6px', marginRight: '6px' }}>
    //     <ConnectWalletButton parentCallback={updateUserWalletAddress} />
    //   </div>
    // </div>
  )
}

export default Nav;