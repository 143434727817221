export const seeds = server => {
  const user = server.create('user', {
      userId: '12345',
      preferredFirstName: 'Bart',
      preferredLastName: 'Simpson',
      emailAddress: 'bart.simpson@mash.com',
      roles: [{ roleCode: 'ARTS'}],
      userWalletAddress: '0xY7HFGKDGND3484850008'
    });

    server.create('collection', { 
      id: 1,
      collection_contract_address: '0x957GH9f48bedc30ddEBb59601a9C79a5Da64e424',
      type: 'collection', 
      title: 'Blurry Vision and Fuzz Sounds',
      description: 'Blurry Vision is a collection about impaired sight. Inasmuch as what can be seen by the eyes in limited ways.',
      creator: 'Angelina Taylor & FX guy',
      owner_address: '0xMA592093230495962648SH494092',
      unit_price: '1.0',
      mash_fee: '0.2',
      supply: '23',
      tags: ['tech'],
      thumbnail_ipfs_url: '/images/blurry.png',
    });

    server.create('collection', { 
      id: 2,
      collection_contract_address: '0x573199f48bedc30ddEBb59601a9C79a5Da64e461',
      type: 'collection', 
      title: 'Clandestine Collab Collection',
      description: 'The clandestine collection in rhythmic waves.',
      creator: 'Benzene Poppa Doo & Genoan Master',
      owner_address: '0xMA592093230495962648SH494092',
      unit_price: '0.1',
      mash_fee: '0.2',
      supply: '13',
      tags: ['scifi','tech'],
      thumbnail_ipfs_url: '/images/orangenoise.png',
    });

    server.create("collection", { 
      id: 3,
      collection_contract_address: '0x732199f48bedc30ddEBb59601a9C79a5Da64e903',
      type: 'collection', 
      title: 'Morph Collabollection',
      description: 'A morph collection.',
      creator: 'Rocco & Baduin',
      owner_address: '0xMA592093230495962648SH494092',
      unit_price: '1.0',
      mash_fee: '0.2',
      supply: '123',
      tags: [ 'scifi','tech'],
      thumbnail_ipfs_url: '/images/orangenoise.png',
    });

    // server.create('collection', { 
    //   id: 4,
    //   collection_contract_address: '0x888263199f48bedc30ddEBb59601a9C79a5Da64e424',
    //   type: 'collection', 
    //   title: 'Clear Vision and Sounds',
    //   description: 'Clear Vision is a collection about impaired sight. Inasmuch as what can be seen by the eyes in limited ways.',
    //   creator: 'Rogue Artist, Geneta, Volshiv',
    //   price: '1 ETH',
    //   supply: '23',
    //   tags: ['tech'],
    //   thumbnail_ipfs_url: '/images/blurry.png',
    // });

    // server.create('collection', { 
    //   id: 5,
    //   collection_contract_address: '0x222573199f48bedc30ddEBb59601a9C79a5Da64e461',
    //   type: 'collection', 
    //   title: 'Crazy Sound and Art',
    //   description: 'The crazy collection in chaotic waves.',
    //   creator: 'BenzenePop, Spizer', 
    //   price: '0.1 ETH',
    //   supply: '13',
    //   tags: ['scifi','tech'],
    //   thumbnail_ipfs_url: '/images/orangenoise.png',
    // });

    server.create("video", { 
      id: 1,
      type: "video",
      collection_id: 1, // this belongs to collection with id = 1
      title: "Dreamy City",
      description: "How about a steampunk city in the sky? let`s imagine a fantasy steampunk city that uses geothermal gas from the depths of the earth as the main source of steam power. This can be a bright and colorful city.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '1:08',
      format: "mp4",
      creator: "Angelina Taylor",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "Abstract", "Cities" ],
      media_ipfs_url: 'https://video.wixstatic.com/video/01efd9_2f5febb192334624b3476feb7edbf7cf/1080p/mp4/file.mp4',
      thumbnail_ipfs_url: 'https://static.wixstatic.com/media/01efd9_4f8c24df41ed403a9b812145d13e4ee5~mv2.png/v1/fill/w_240,h_240,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Dreamy_city.png',
    });
    
    server.create("video", { 
      id: 2,
      type: "video",
      collection_id: 1, // this belongs to collection with id = 1
      title: "Blurry Vision with myopic detriments and confusions",
      description: "Blurry Vision is a collection about impaired sight.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '0:52', 
      format: "mp4",
      creator: "Angelina Taylor",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "scifi","tech"],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmSpaq28dSLA5HGFRSvcexvNGUVPY7Y5697PXeiCpcRQ5y',
      thumbnail_ipfs_url: '/images/thumbnail_ATaylor.png',
    });

    server.create("video", { 
      id: 3,
      type: "video",
      collection_id: 1, // this belongs to collection with id = 1
      title: "19200430",
      description: "Lorem ipsum dolorem epsom salt.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '0:52', 
      format: "mp4",
      creator: "Angelina Taylor",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "scifi", "tech", "cityscape"],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmQADdcZAW9ms9Bi2fSpk4ae8PoYLG9VMXwySXvnAgtWmu/bastion_mara_visual/Dance.mp4',
      thumbnail_ipfs_url: '/images/collection2/Dance.png',
    });

    server.create("video", { 
      id: 4,
      type: "video",
      collection_id: 1, // this belongs to collection with id = 1
      title: "0430",
      description: "Blurry Vision is a collection about impaired sight.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '0:52', 
      format: "mp4",
      creator: "Angelina Taylor",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "scifi","tech"],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmNRnJ3xptqyXoXsXfvtNp4EqnHJVTiHnE36cX6KYBEvRU/dirtychefs_genc_1/Visual/B0NEHER01.mp4',
      thumbnail_ipfs_url: '/images/collection1/B0NEHER01.png',
    });
    
    server.create("video", { 
      id: 5,
      type: "video",
      collection_id: 1, // this belongs to collection with id = 1
      title: "Dreamy City",
      description: "How about a steampunk city in the sky? let`s imagine a fantasy steampunk city that uses geothermal gas from the depths of the earth as the main source of steam power. This can be a bright and colorful city.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '1:08',
      format: "mp4",
      creator: "Angelina Taylor",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "Abstract", "Cities" ],
      media_ipfs_url: 'https://video.wixstatic.com/video/01efd9_2f5febb192334624b3476feb7edbf7cf/1080p/mp4/file.mp4',
      thumbnail_ipfs_url: 'https://static.wixstatic.com/media/01efd9_4f8c24df41ed403a9b812145d13e4ee5~mv2.png/v1/fill/w_240,h_240,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Dreamy_city.png',
    });

    server.create("audio", { 
      id: 1,
      type: "audio",
      collection_id: 1, // this belongs to collection with id = 1
      title: "Good Vibes",
      description: "Blurry Vision is a collection about impaired sight.",
      list_price: "1.0",
      royalty_fee: "0.1",
      length: "3 min",
      format: "mp3",
      creator: "FX guy",
      owner_address: "0xFX4838048D5843928429484932844810",
      tags: [
        "vibes"
      ],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmWxF6v4q6RjGtCiRAWNAYjhVGeMSEL3wkYqTvL6h5Lbmu',
      thumbnail_ipfs_url: '/images/waveform1.png',
    });

    server.create("audio", { 
      id: 2,
      type: 'audio',
      collection_id: 1, // this belongs to collection with id = 1
      title: 'Crypto Boy remix (with samples from under the void',
      description: "This is a fragment of the song 'Crypto Boy' remixed.",
      list_price: "1.0",
      royalty_fee: "0.1",
      length: '1 min',
      format: 'mp3',
      creator: 'FX guy',
      owner_address: "0xFX4838048D5843928429484932844810",
      tags: [
        'remix', 
      ],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmWxF6v4q6RjGtCiRAWNAYjhVGeMSEL3wkYqTvL6h5Lbmu',
      thumbnail_ipfs_url: '/images/waveform2.png',
    });

    server.create("image", { 
      id: 1,
      type: "image",
      title: "Art One",
      description: "First Artwork.",
      list_price: "1.0",
      royalty_fee: '0.1',
      length: "",
      format: "png",
      creator: "Dirty Chefs",
      owner_address: "0xDC4838048D5843928429484932844810",
      tags: [
        "minimalist"
      ],
      media_ipfs_url: '/images/orangenoise.png',
      thumbnail_ipfs_url: '/images/orangenoise.png',
    });

    // part of collection 2
    server.create("video", { 
      id: 6,
      type: "video",
      collection_id: 2, // this belongs to collection with id = 2
      title: "Launch City",
      description: "A steampunk city that uses geothermal gas from the depths of the earth as the main source of steam power. This can be a bright and colorful city.",
      list_price: "1.0",
      royalty_fee: "0.1",
      duration: '1:08',
      format: "mp4",
      creator: "John Wick",
      owner_address: "0xAT4838048D5843928429484932844810",
      tags: [ "Abstract", "Cities" ],
      media_ipfs_url: 'https://video.wixstatic.com/video/01efd9_2f5febb192334624b3476feb7edbf7cf/1080p/mp4/file.mp4',
      thumbnail_ipfs_url: 'https://static.wixstatic.com/media/01efd9_4f8c24df41ed403a9b812145d13e4ee5~mv2.png/v1/fill/w_240,h_240,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Dreamy_city.png',
    });

    server.create("audio", { 
      id: 3,
      type: 'audio',
      collection_id: 2, // this belongs to collection with id = 2
      title: 'Hakuna Matata',
      description: "No worries.",
      list_price: "1.0",
      royalty_fee: "0.1",
      length: '1 min',
      format: 'mp3',
      creator: 'Pyro',
      owner_address: "0xFX4838048D5843928429484932844810",
      tags: [
        'remix', 
      ],
      media_ipfs_url: 'https://mashnft.mypinata.cloud/ipfs/QmWxF6v4q6RjGtCiRAWNAYjhVGeMSEL3wkYqTvL6h5Lbmu',
      thumbnail_ipfs_url: '/images/waveform2.png',
    });
}