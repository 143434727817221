import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory, withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import AssetSelectionContext from './AssetSelectionContext';
import MovieClip from './MovieClip';
import PreMashView from './PreMashView';
import Audios from './Audios';
import Utilities from '../../utility/utilities';
import protectedRoute from '../../protectedRoute';
import CollectionDetails from './CollectionDetails';
import VideoCard from './VideoCard';
import AudioCard from './AudioCard';
import UserContext from '../../UserContext';
import './PreMash.css';

const PreMash = (props) => {

  let history = useHistory();

  const location = useLocation();

  const userContext = useContext(UserContext);
  console.log('userContext as seen by Edit Page: ', userContext);

  const incomingPayload = location.payLoad; // note: it's always called "payLoad" from the location object
  console.log("incomingPayload from Mashboard page; ", incomingPayload);

  const [ mashing, setMashing ] = useState(false); // if currently mashing

  let [ mashed, setMashed ] = useState(false); // if done mashing

  const [ mashedPayload, setMashedPayload ] = useState(incomingPayload);

  useEffect(() => {
    checkUsername();
  }, []);

  useEffect(() => {
    console.log('mashing state is now ', mashing);
  }, [mashing]);

  useEffect(() => {
    if (mashed) {
      console.log('MASHING is DONE ', mashed);
      // pass the payLoad to the premint page after successful MASH
      Utilities.sendPayLoad(props, '/premint', mashedPayload);
    }
  }, [mashed]);

  const triggerMashing = () => {
    console.log("MASHing triggerred from ColumnB...");
    if (isWalletConnected()) {
      setMashing(true);
      // simulate some delay here to simulate the process of mashing"
      console.log("Currently Mashing.....");
      setTimeout(() => {
        // delay a few seconds then set "mashed" state to true (remove this when actual method for mashing is in place)
        // just set mashed varialbe to true upon successful mash
        setMashed(true);
        setMashing(false);
      }, 3000);
    } else {
      alert("Wallet not connected.\n🦊 Please connect your wallet before Mashing.\nClick the 'Connect Wallet' button at top right.");
    }
  }

  const isWalletConnected = () => {
    let walletConnected = false;
    if (userContext.user_wallet_address.length > 0 ) {
      walletConnected = true
    }
    return walletConnected;
  }

  const goToCollections = () => {
    Utilities.sendPayLoad(props, '/collections', mashedPayload);
  }

  const goToMashboard = () => {
    Utilities.sendPayLoad(props, '/mashboard', mashedPayload);
  }
  
  const checkUsername = () => {
    // this is a check to make sure the payLoad is still intact; sends user to Profile page to re-login if broken data flow
    if (incomingPayload.username === undefined) {
      Utilities.sendPayLoad(props, '/gate', mashedPayload);
    }
  }

  return (
    <AssetSelectionContext.Provider value={mashedPayload}>
      <div className="Edit">
        <Helmet htmlAttributes>
          <html lang="en" />
          <title>Mashboard - Edit</title>
          <meta name="description" content="Mashboard Edit" />
        </Helmet>
        <div className="preview-container">
        <p onClick={() => goToMashboard()}>&lt; Back to Selection Page</p>
          <PreMashView parentCallback={triggerMashing}></PreMashView>
        </div>
        <div className="column-ab-container">
          <div className="flow-wrapper">
            <p>Your selected Audio and Video are shown below.<br></br>
                Please confirm and then click the MASH button.
            </p>
          </div>
          <div className="videocard-wrapper">
            <VideoCard item={incomingPayload.video}></VideoCard>
          </div>
          <div className="audiocard-wrapper">
            <AudioCard item={incomingPayload.audio}></AudioCard>
          </div>
        </div>
        {
          mashing ? (
            <>
              <div style={{ backgroundColor: 'gray' }} className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  {/* the modal itself */}
                <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '75%' }}>
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between py-1 px-4 border-b border-solid border-slate-200 rounded-t">
                      <h4 className="py-2 text-sm font-semibold">
                        MASHing in progress....
                      </h4>
                    </div>
                    {/*body*/}
                    <div style={{ textAlign: 'center', width: '100%', border: '1px dotted gray' }} className="relative p-1">

                      <p>Please wait...</p>
                      <p>We are currently generating a new mashed movie clip for you...</p>
                      <p>Your new MASH clip will be ready soon!</p>

                      <svg style={{ textAlign: 'center', width: '100%' }} role="status" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }
      </div>
    </AssetSelectionContext.Provider>
  );
}

export default protectedRoute(PreMash);
