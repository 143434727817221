import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Splash from "./page-sections/Splash";
import Featured from './page-sections/Featured';
import Footer from './page-sections/Footer';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import "./Home.css";

const Home = (props) => {

  useEffect(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://join.mashlabs.xyz');
  }, []);
  
  return (
    <>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>MASH Home</title>
        <meta name="description" content="Mash Home" />
      </Helmet>
      <BrowserView>
        <div className="home">
          <div className="splash-wrapper">
            <Link to='/collections'>
              <img className="splash-image" src="/images/Mash_Highlight_Still.png" />
            </Link>
          </div>        
          <div className="section section--splash">
            <Splash></Splash>
          </div>
          <div className="section">
            <Featured></Featured>
          </div>
          <div className="section">
            <Footer></Footer>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="home">
          <div className="splash-wrapper">
            <Link to='/collections'>
              <img className="splash-image" src="./images/Mash_Highlight_Still.png" />
            </Link>
          </div>
        </div>
      </MobileView>
      
    </>
  )
}

export default Home;
