import React from 'react';

import AssetSelectionContext from './AssetSelectionContext';
import ReactAudioPlayer from 'react-audio-player';
import Utilities from '../../utility/utilities.js';
import { Player } from 'video-react';
import './ColumnB.css';

export default class ColumnB extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // console.log('context:', this.context); // 'this.context' is for class components
    }

    mashHandler(status) {
        // trigger the callBack
        this.props.parentCallback(status);
    }

    render() {
        return (
            <div className="ColumnB">
                <div className="video-title-wrapper">
                    <p class="video-title">{this.context.video.title}</p>
                </div>
                <div className="media-players">
                    <Player
                        playsInline
                        poster={this.context.video.thumbnail_ipfs_url}
                        src={this.context.video.media_ipfs_url}
                        className="video-player"
                    />

                    <div className="py-5">
                        <p class="audio-title">{this.context.audio.title}</p>
                    </div> 
                    
                    <ReactAudioPlayer
                        controls
                        src={this.context.audio.media_ipfs_url}
                        className="react-audio-player"
                    />
                </div>

                <div className="mash-button-wrapper">
                    <button onClick={ () => this.mashHandler(true) } className="mash-button my-5 py-2 px-4 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg" type="button" style={{ backgroundColor: 'gray'}}>
                    CONFIRM SELECTIONS
                    </button>
                </div>

            </div>
        )

    }
}

ColumnB.contextType = AssetSelectionContext; // You need this for class components