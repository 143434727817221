import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";
import AssetSelectionContext from './AssetSelectionContext';
import Videos from './Videos';
import ColumnB from './ColumnB';
import Audios from './Audios';
import Utilities from '../../utility/utilities';
import protectedRoute from '../../protectedRoute';
import CollectionDetails from './CollectionDetails';
import UserContext from '../../UserContext';
import { Auth, Hub } from 'aws-amplify';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { getCollectionMasterContractInstance } from '../../interface/api/MashNFT.js'
import './Mashboard.css';

const Mashboard = (props) => {
  let history = useHistory();
  const location = useLocation();

  let incomingPayload = location.payLoad; // note: it's always called "payLoad" from the location object

  const [dataPayload, setDataPayload] = useState(incomingPayload); // initially defaults to incomingpayload

  // if incomingPayload is empty then the user may probably be hitting the Mashboard url with a collection_id parameter
  // grab the collection_id and use that id to fetch the data fields for that particular collection
  if (dataPayload === undefined) {
    const queryParameters = new URLSearchParams(location.search);
    const collectionId = queryParameters.get("collection_id");
    
    if (collectionId !== null) {
      fetchCollection(collectionId).then(function(result) {
        setDataPayload(result);
      });
    }
  }

  async function fetchCollection(id) {
    const mash_contract_factory = await getCollectionMasterContractInstance("0xc4af4A45515E3086eaEEfB8454Cd81905C4723e7");
    const solidity_collections = await mash_contract_factory.methods.getCollections().call();
    const collections = [];
    for (let i = 0; i < solidity_collections.length; ++i) {
        const collection = {
            "id": String(solidity_collections[i][0]),
            "collectionContractAddress": solidity_collections[i][10],
            "type": 'collection',
            "title": solidity_collections[i][3],
            "description": solidity_collections[i][4],
            "creator": solidity_collections[i][6],
            "visual_creator_name": solidity_collections[i][6],
            "audio_creator_name": solidity_collections[i][7],
            "price": String(solidity_collections[i][2]),
            "supply": solidity_collections[i][1],
            "tags": [ 'scifi','tech'],
            "thumbnail_ipfs_url": solidity_collections[i][5]
        }
        if (collection.id == 2 || collection.id == 3) {
          collections.push(collection);
        }
    }
    const specificCollection = collections.filter((collection) => (collection.id === id));
    return specificCollection[0];
  }
  
  const userContext = useContext(UserContext);
  const [user, setUser] = useState(null);
  
  const updateSelectedAssetsWithUserData = (userContext) => {
    // This merges the userdata into the selectedAssetsPayload object in state
    const fusedObject = {...selectedAssetsPayload, ...userContext};
    setSelectedAssetsPayload(fusedObject); // update selectedAssetsPayload object via hook method
  }

  const updateSelectedAssetsWithSelectedCollectionData = (dataPayload) => {
    // This merges the chosen collection data into the selectedAssetsPayload object in state
    const chosenCollectionObject = {...selectedAssetsPayload, ...dataPayload};
    setSelectedAssetsPayload(chosenCollectionObject); // update selectedAssetsPayload object via hook method
  }

  const [ selectedAssetsPayload, setSelectedAssetsPayload ] = useState(
    {
      id: 'mymashednft',
      title: 'My Mashed NFT Creation',
      description: 'This is a mashed NFT that used a video from a visual artist and an audio from a musical artist.',
      tags: ['scifi','tech'],
      thumbnail_ipfs_url: '/images/MASH-screen.png', 
      user_wallet_address: userContext.user_wallet_address,
      username: userContext.username,
      mashed_creation_url: '', // this will be set once the Mashed clip is uploaded to pinata
      media_ipfs_url: "https://ak.picdn.net/shutterstock/videos/1066836493/preview/stock-footage-big-city-businessman-uses-smartphone-stands-on-crowded-street-e-commerce-visualization-of.webm",
      video: {
        id: 'vid1',
        type: 'video', 
        title:'Choose a Visual to get started.',
        length: '8 sec',
        duration: '1:08',
        creator: 'video creator',
        owner_address: "0xAT4838048D5843928429484932844810",
        tags: ['scifi','tech'],
        thumbnail_ipfs_url: '/images/MASH-screen.png',
        media_ipfs_url:'https://ak.picdn.net/shutterstock/videos/1088444883/preview/stock-footage-nft-virtual-city-real-estate-digital-land-property-blockchain-metaverse-platform.mp4',
      },
      audio: {
        id: 'audio1',
        type: 'audio',
        title:'Choose a Visual to get started.',
        length: '3 minutes',
        duration: '3:20',
        creator: 'audiophile1',
        owner_address: "0xAT4838048D5843928429484932844810",
        tags: ['rock'], 
        thumbnail_ipfs_url: '/images/waveform1.png',
        media_ipfs_url:'https://ak.picdn.net/shutterstock/videos/1088444883/preview/stock-footage-nft-virtual-city-real-estate-digital-land-property-blockchain-metaverse-platform.mp4',
      }
    }
  );

  useEffect(() => {
    updateSelectedAssetsWithUserData(userContext);
    updateSelectedAssetsWithSelectedCollectionData(dataPayload); // put chosen collection data into payload
  }, []);

  const updateAudioSelection = (item) => {
    // merge incoming item to the selection object in state
    const newSelectionObject = {...selectedAssetsPayload, ...{audio:item}};
    setSelectedAssetsPayload(newSelectionObject); // update payLoad data via hook method
  }

  const updateVideoSelection = (item) => {
    // merge incoming item to the selection object in state
    const newSelectionObject = {...selectedAssetsPayload, ...{video:item}};
    setSelectedAssetsPayload(newSelectionObject); // update payLoad data via hook method
  }

  const goToPreMashPage = () => {
    if (isWalletConnected()) {
      updateSelectedAssetsWithUserData(userContext); // Make sure to update payLoad
      Utilities.sendPayLoad(props, '/premash', selectedAssetsPayload);
    } else {
      alert("Wallet not connected.\n 🦊 Please connect your wallet before proceeding.\n Click the 'Connect Wallet' button at top right.");
    }
  }

  const goToCollections = () => {
    Utilities.sendPayLoad(props, '/collections', selectedAssetsPayload);
  }

  const isWalletConnected = () => {
    let walletConnected = false;
    if (userContext.user_wallet_address.length > 0 ) {
      walletConnected = true
    }
    return walletConnected;
  }

  return (
    <AssetSelectionContext.Provider value={selectedAssetsPayload}>
      <>
        <Helmet htmlAttributes>
          <html lang="en" />
          <title>Mashboard</title>
          <meta name="description" content="Mash Home" />
        </Helmet>

        <BrowserView>
          <div className="Mashboard">
            
            <div className="flow-direction">
              <p onClick={() => { goToCollections() }}>&lt; Back to Collections</p>
            </div>

            <div className="collection-details-wrapper">
              { dataPayload && <CollectionDetails collection={dataPayload}></CollectionDetails> }
            </div>

            <div className="wrapper">

              <div className="preview-container">
                <ColumnB parentCallback={goToPreMashPage}></ColumnB>
              </div>
              <div className="videos-audios-container">
                <div className="videos-wrapper">
                  {dataPayload && <Videos collection={dataPayload} parentCallback={updateVideoSelection}></Videos>}
                </div>

                <br></br>

                <div className="column-c-container">
                  {dataPayload && <Audios collection={dataPayload} parentCallback={updateAudioSelection}></Audios>}
                </div>
              </div>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <p style={{ color: 'white', fontSize: '12px' }}>Mobile devices are not yet supported.</p>
        </MobileView>
      </>
    </AssetSelectionContext.Provider>
  );
}

export default Mashboard;
