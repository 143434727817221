import React from 'react';
import { Link } from 'react-router-dom';
import './Splash.css';

function Splash() {
  return (
    <div className="splash">
      <div style={{ textAlign: "center", display: "flex" }}>
        <span className="welcome">
          Welcome to the new way to discover and engage with creative artists from around the world.
        </span>
        <span className="launcher-wrapper">
          <Link to='/collections'>
            <button style={{ backgroundColor: "#2979FF", whiteSpace: "nowrap" }} className="inline-flex items-center justify-center px-2 py-0 border border-transparent text-base font-small rounded-md text-white hover:bg-indigo-700">
              <span style={{ fontWeight: 600, fontSize: "18px", padding: "10px 20px" }}>
                Open Mash in your browser
              </span>
            </button>
          </Link>
        </span>
      </div>
    </div>
  );
}

export default Splash;
