import React, { useState, useEffect } from 'react';
import {
  connectWallet,
  getCurrentWalletConnected,
} from "../utility/interact";

const ConnectWalletButton = (props) => {

  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [userWalletStatus, setUserWalletStatus] = useState("");

  // ToDo: refactor callbacks via direct context setting instead of callback (fix the bug why next line below does not work)
  // const [userContext, setUserContext] = useContext(UserContext); // connect with the context
  // const { userContext, setUserContext } = useContext(UserContext); // connect with the context

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setUserWalletStatus(walletResponse.status);
    setUserWalletAddress(walletResponse.address);
    props.parentCallback(walletResponse.address); // set the userWalletAddress upstream in the global userContext 
  }

  useEffect(() => {
    walletOperations();
   }, []);

  async function walletOperations() {
    const { address, status } = await getCurrentWalletConnected();
    setUserWalletAddress(address);
    setUserWalletStatus(status);
    props.parentCallback(address);
    addWalletListener();
  }

  async function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setUserWalletAddress(accounts[0]);
          props.parentCallback(accounts[0]);
          setUserWalletStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setUserWalletAddress("");
          props.parentCallback("");
          setUserWalletStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setUserWalletStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`} rel="noopener noreferrer">
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <button id="walletButton" onClick={connectWalletPressed} style={{ backgroundColor: "#2979FF"}} className="inline-flex items-center justify-center px-2 py-0 border border-transparent text-base font-small rounded-md text-white hover:bg-indigo-700">
      <span style={{ fontSize: '12px' }}>
        {userWalletAddress.length > 0 ? (
          "Connected: " +
          String(userWalletAddress).substring(0, 6) +
          "..." +
          String(userWalletAddress).substring(38)
        ) : "Connect Wallet"
        }
      </span>
    </button>
  );
}

export default ConnectWalletButton;
