const { Alchemy, Network, Wallet, Utils } = require("alchemy-sdk");
const CONTRACT_ABI = require("../abi/mashnft_contract.json");
const COLLECTION_CONTRACT_ABI = require("../abi/mash_collection_factory_contract.json");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(
    "https://eth-sepolia.g.alchemy.com/v2/pUzxr-Vs9CImMU6Vf7mM3rFCoidSfEek"
);

export const getContractInstance = async (mashnft_contract_address) => {
    return new web3.eth.Contract(CONTRACT_ABI, mashnft_contract_address);
}

export const getCollectionMasterContractInstance = async (mashnft_collection_contract_address) => {
    return new web3.eth.Contract(COLLECTION_CONTRACT_ABI, mashnft_collection_contract_address);
}

export const getMaxSupply = async (mashnft_contract_instance) => {
  const max_tokens = await mashnft_contract_instance.methods.getMaxSupply().call();
  return max_tokens;
};

export const getMintPrice = async (mashnft_contract_instance) => {
  const mint_price = await mashnft_contract_instance.methods.getMintPrice().call();
  return mint_price;
};

export const getVisualMediaAndMetadata = async (mashnft_contract_instance) => {
  const visual_media = await mashnft_contract_instance.methods.getVisualMediaAndMetadata().call();
  return visual_media;
};

export const getAudioMediaAndMetadata = async (mashnft_contract_instance) => {
  const audio_media = await mashnft_contract_instance.methods.getAudioMediaAndMetadata().call();
  return audio_media;
};

export const mintNFT = async (user_address, token_uri, mash_contract_address) => {
    window.contract = await new web3.eth.Contract(CONTRACT_ABI, mash_contract_address);

    const nonce = await web3.eth.getTransactionCount(user_address, 'latest'); // nonce starts counting from 0
    const standard_gas_limit = Utils.parseUnits("30000", "gwei");
    const latest_block = await web3.eth.getBlock("latest");
    const latest_gas_limit = String(latest_block.gasLimit) ? (latest_block.gasLimit <= standard_gas_limit) : String(standard_gas_limit);
    const test_gas_price = String(web3.utils.toWei('0.000000024', 'ether') / 1000000000);
    const test_eth_value = String(web3.utils.toWei('0.000024', 'ether'));

    const transaction = {
        'to': mash_contract_address, // faucet address to return eth
        'from': user_address,
        'gas': test_gas_price,
        'nonce': web3.utils.toHex(nonce),
        'gasLimit': latest_gas_limit,
        'maxPriorityFeePerGas': String(Utils.parseUnits("5", "gwei")),
        'maxFeePerGas': String(Utils.parseUnits("20", "gwei")),
        'value': test_eth_value,
        'type': web3.utils.toHex(2),
        'chainId': 11155111,
        'data': window.contract.methods
            .mint(user_address, token_uri)
            .encodeABI(),
    };

    try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transaction],
            });
        return {
            success: true,
            status:
            "✅ Check out your transaction on Etherscan: https://sepolia.etherscan.io/tx/" +
            txHash,
        };
    } catch (error) {
        return {
        success: false,
        status: "ERROR: Something went wrong -> " + error.message,
        };
    }
};
